import React from "react"

import Layout from "../components/layout"
import { FormattedMessage } from "gatsby-plugin-intl"

const NotFoundPage = () => (
  <Layout>
    <h1><FormattedMessage id="notfound.title" /></h1>
    <p><FormattedMessage id="notfound.content" /></p>
  </Layout>
)

export default NotFoundPage
